<template>
  <v-row class="item-bio-panel">
    <!-- item profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-img class="mb-3" :src="itemData.image ? itemData.image : ''"></v-img>
          <span class="mb-2">{{ itemData.name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Event Name:</span>
              <span class="text--secondary">{{ itemData.name }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Start Date:</span>
              <span class="text--secondary">{{ itemData.start_date }}</span>
            </v-list-item>


            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">End Date:</span>
              <span class="text--secondary">{{ itemData.end_date }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Remaining :</span>
              <span class="text--secondary">{{ itemData.remaining_time }} Day</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Mobile Number :</span>
              <span class="text--secondary">{{ itemData.mobile_number }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Email :</span>
              <span class="text--secondary">{{ itemData.email }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Description :</span>
              <span class="text-capitalize">{{ itemData.description ? itemData.description.substring(0, 40) +
                  '...' : itemData.description
              }}</span>
            </v-list-item>

            <v-card-actions class="justify-center mt-3">
              <v-btn color="primary" class="me-3" @click="isBioDialogOpen = !isBioDialogOpen">
                Edit
              </v-btn>
              <!-- <v-btn color="error" outlined @click="removeItem()">
                Delete
              </v-btn> -->
            </v-card-actions>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <event-bio-edit :is-bio-dialog-open.sync="isBioDialogOpen" :item-data="itemData" @refresh="updateValue($event)">
      </event-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import EventBioEdit from './EventBioEdit.vue'

export default {
  components: {
    EventBioEdit,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('refresh', value)
    },
    removeItem() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
      })
        .then(result => {
          if (result.isConfirmed) {
            let data = {
                "order_id" : id,
                "reason":document.querySelector(".swal2-input").value,
            }
            store
              .dispatch('app-events/deleteEvent', data)
              .then(() => {
                Swal.fire('Deleted!', 'entry has been deleted.', 'success')
                this.$router.push({ path: '/apps/events/list' })
              })
              .catch(error => {
                Swal.fire({
                  icon: 'error',
                  title: `err`,
                  text: error,
                })
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },

  setup() {
    const isBioDialogOpen = ref(false)

    return {
      avatarText,
      kFormatter,
      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
