<template>
  <div id="item-view">
    <v-row>
      <v-col
        cols="12"
      >
        <event-bio-panel
          :item-data="itemData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refresh="updateItemValue($event )"
        ></event-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { mdiAccountOutline } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import EventsStoreModule from '../EventsStoreModule'

// eslint-disable-next-line object-curly-newline
import EventBioPanel from './event-bio-panel/EventBioPanel.vue'

export default {
  components: {
    EventBioPanel,
  },
  methods: {
    updateItemValue(value) {
      this.itemData = value
    },

    // updateEmployeeValue(data) {
    //   // push stored employee to companyData
    //   this.companyData.employees.push(data)
    // },
  },
  setup() {
    const ITEMS_APP_STORE_MODULE_NAME = 'app-events'

    // Register module
    if (!store.hasModule(ITEMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ITEMS_APP_STORE_MODULE_NAME, EventsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEMS_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEMS_APP_STORE_MODULE_NAME)
    })

    const itemData = ref({})
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-events/fetchEvent', { id: router.currentRoute.params.id })
      .then(response => {
        itemData.value = response.data.data
        console.log('itemData', itemData.value)
      })
      .catch(error => {
        if (error.response.status === 404) {
          itemData.value = {}
        }
      })

    const tabs = [{ icon: mdiAccountOutline, title: 'Employees' }]

    return {
      tabs,
      isPlanUpgradeDialogVisible,
      itemData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
