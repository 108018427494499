<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="1250px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="item-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit Event Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating Event details will receive a privacy audit.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="eventDataLocal.name" :rules="[validators.customeRequired]" outlined dense
                label="Event Name"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="eventDataLocal.email" outlined dense
                :rules="[validators.required, validators.emailValidator]" label="Email">
              </v-text-field>
            </v-col>



            <v-col cols="12" sm="6">
              <v-text-field v-model="eventDataLocal.mobile_number" outlined dense
                :rules="[validators.required, validators.integerValidator]" type="number" hide-spin-buttons
                label="Mobile Number"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-file-input v-model="eventDataLocal.image" accept="image/png, image/jpeg, image/bmp"
                placeholder="Choose an image" outlined dense label="Upload Image" hide-details="auto"
                hint="Image dimensions: min-width: 126px, max-width: 252px, min-height: 95px, max-height: 189px, max-file-size: 512kb"
                class="mb-6"></v-file-input>
            </v-col>

            <v-col cols="12" sm="6">

              <v-menu v-model="modal" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="eventDataLocal.start_date" label="Start Date" :prepend-icon="icons.mdiCalendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="eventDataLocal.start_date" color="primary" @input="modal = false">
                </v-date-picker>
              </v-menu>

            </v-col>
            <v-col cols="12" sm="6">
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="eventDataLocal.end_date" label="End Date" :prepend-icon="icons.mdiCalendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="eventDataLocal.end_date" color="primary" @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-textarea :rules="[validators.required]" v-model="eventDataLocal.description" outlined label="Description" placeholder="Description">
              </v-textarea>
            </v-col>



          </v-row>


          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit">
                submit
              </v-btn>

              <v-btn outlined color="secondary" @click.prevent="$emit('update:is-bio-dialog-open', false)">
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { customeRequired, emailValidator, imageValidator, integerValidator, required } from '@core/utils/validation'
import { mdiDelete, mdiPlus } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    itemData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const modal = ref(false)
    const menu2 = ref(false)
    const isBillingAddress = ref(true)

    const eventDataLocal = ref({})

    eventDataLocal.value = props.itemData

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetItemData = () => {
      eventDataLocal.value = JSON.parse(JSON.stringify(eventDataLocal))
      resetForm()
    }

    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        if (eventDataLocal.value.image === props.itemData.image) {
          delete eventDataLocal.value.image
        }
        try {
          await store.dispatch('app-events/editEvent', eventDataLocal.value).then(response => {
            eventDataLocal.value.image = response.data.data.event.image
          })

          Swal.fire('Updated!', 'data has been updated successfully.', 'success')
          emit('refresh', eventDataLocal.value)
          emit('update:is-bio-dialog-open', false)
        } catch (error) {
          Object.entries(error.data.message).forEach(err => {
            Swal.fire({
              icon: 'error',
              title: `${err[0]}`,
              text: `${err[1][0]}`,
              footer:
                'Desired dimensions: min-width: 833px; max-width: 1670px; min-height: 625px; max-height: 1253px; max-file-size: 512kb',
            })
          })
        }
      } else {
        validate()
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        eventDataLocal.value = JSON.parse(JSON.stringify(props.itemData))
      },
    )

    return {
      eventDataLocal,
      form,
      valid,
      resetItemData,
      validate,
      onSubmit,
      modal,
      menu2,
      // validation
      validators: {
        required,
        emailValidator,
        imageValidator,
        integerValidator,
        customeRequired,
      },
      icons: {
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
